<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <div
                        :class="
                            smAndUp ? 'text-h2' : 'text-h4 font-weight-light'
                        "
                    >
                        <span class="red--text text--darken-4">浦</span>安駅前店
                    </div>
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text text--darken-4">アクセス</span
                        >:<span :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >東西線 浦安駅徒歩30秒!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text text--darken-4">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県浦安市当代島1丁目1-22
                            佃甚ビル4階（中央入り口側）</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text text--darken-4">営業時間</span
                        >:<span :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text text--darken-4">定休日</span
                        >:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >なし</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="top center"
                            height="360px"
                            :src="imageMv"
                        ></v-img>
                        <!-- <v-card-title class="pl-0"
                            >東西線 浦安駅徒歩30秒!</v-card-title
                        >
                        <v-card-subtitle class="pl-0">
                            千葉県浦安市当代島1丁目1-22
                            佃甚ビル4階（中央入り口側）
                        </v-card-subtitle> -->
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="top center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1656469205553!6m8!1m7!1sf0Om6h2w4PXR8DGdkFBMbg!2m2!1d35.66604566229744!2d139.8926458835685!3f222.14883558407777!4f7.008425303595473!5f0.7820865974627469"
                            width="100%"
                            height="360px"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                        <v-card-text
                            >※Googleストリートビューの画像ではREVOISTの看板が表示されていない場合がありますが実際には店舗前に掲示されています。</v-card-text
                        >
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mt-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text text--darken-4"
                            >浦安駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text--darken-4 text-md-h6"
                                >1</span
                            >.
                            改札を出て右側の北口（高架線下の信号）に進んでください。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text--darken-4 text-md-h6"
                                >2</span
                            >. 高架線下の信号を渡り、右側に行きます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text--darken-4 text-md-h6"
                                >3</span
                            >.
                            サイゼリヤ、松屋、郵便局の入った建物の中央入り口側の4階です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text--darken-4 text-md-h6"
                                >4</span
                            >.
                            中央入り口は建物の右側の脇道を入ったところにあります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text--darken-4 text-md-h6"
                                >5</span
                            >. こちらが中央入り口になります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text--darken-4 text-md-h6"
                                >6</span
                            >. エントランスのエレベーターで4階に上がります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text--darken-4 text-md-h6"
                                >7</span
                            >. こちらの自動扉を開けて入ります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessG"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text--darken-4 text-md-h6"
                                >8</span
                            >.
                            廊下の一番奥、右側の扉がREVOISTの入口扉になります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessH"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの10号店です！
浦安駅徒歩30秒のバイパス沿いの商業ビル4階の駅近のジムです！
浦安でパーソナルトレーニングを受けたい方にとって待望の出店です。
現在、浦安にお住まいのお客様の新規体験を募集中です！
理学療法にも精通したパーソナルトレーナーが、あなたのレベルに合わせて、なりたい理想の姿に向かって、熱量を持って、丁寧に説明しながらレッスンします！
`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '浦安駅前店',
            center: {
                lat: 35.66598752050757,
                lng: 139.89233121036366,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.66598752050757,
                        lng: 139.89233121036366,
                    },
                    title: '浦安駅前店',
                },
            ],
            message: `パーソナルジム REVOISTの10号店です！<br />
浦安駅徒歩30秒のバイパス沿いの商業ビル4階の駅近のジムです！<br />
浦安でパーソナルトレーニングを受けたい方にとって待望の出店です。<br />
現在、浦安にお住まいのお客様の新規体験を募集中です！<br />
理学療法にも精通したパーソナルトレーナーが、あなたのレベルに合わせて、なりたい理想の姿に向かって、熱量を持って、丁寧に説明しながらレッスンします！<br /><br />

浦安駅前店トレーナー：小西晟智<br />
日々の生活習慣の中にトレーニングを取り入れて頂き、身体も心もより良い状態になるお手伝い、サポートが出来ればと思っています！
目標のカラダに向かって、楽しくトレーニングしましょう！<br />

`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '浦安駅前店',
                    disabled: true,
                    href: 'access-7',
                },
            ]
        },

        imageMv() {
            return 'static/pc_access_10/urayasu_hero.jpg'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_10/urayasu_1.jpg'
                : 'static/pc_access_10/urayasu_1.jpg'
        },
        accessB() {
            return 'static/pc_access_10/urayasu_2.jpg'
        },
        accessC() {
            return 'static/pc_access_10/urayasu_3.jpg'
        },
        accessD() {
            return 'static/pc_access_10/urayasu_4.jpg'
        },
        accessE() {
            return 'static/pc_access_10/urayasu_5.jpg'
        },
        accessF() {
            return 'static/pc_access_10/urayasu_6.jpg'
        },
        accessG() {
            return 'static/pc_access_10/urayasu_7.jpg'
        },
        accessH() {
            return 'static/pc_access_10/urayasu_8.jpg'
        },
        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/UrayasuEkimae.jpg'
                : 'static/landing/UrayasuEkimae.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/浦安駅前店　小西晟智トレーナー.jpg'
                : 'static/landing/trainer/浦安駅前店　小西晟智トレーナー.jpg'
        },
    },
}
</script>
<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
